
<template>
    <div >    
        <span >{{msg}}</span>
    </div>
</template>

<script>

import {ddCallback, casCallback} from '../../../api/login';

export default {
    name: "login-oauth2-callback",
    props: {
    },
    components: {
    },
    data () {
        let self = (window.x = this)
        return {
            msg: '正在跳转...',
            redirect: '',
        }
    },
    methods: {
        do_store_user(user){
            let self = this;
            self.$store.commit('user/loginSuccess', user);
            localStorage.setItem("user", JSON.stringify(user) );    // 保存token
            // console.log("final: ", self.$route.query.source_url)
            if (self.$route.query.source_url){
                if (self.$route.query.source_url.startsWith('http://') || 
                    self.$route.query.source_url.startsWith('https://')){
                        window.location.href = self.$route.query.source_url;
                    }
                else{
                    if (self.$route.query.source_url.startsWith("/v3/")){
                        // self.$router.push({ path: self.$route.query.source_url, replace: true }).catch(()=>{});
                        // self.$router.go(self.$route.query.source_url)
                        window.location.href = self.$route.query.source_url;
                    }else{
                        self.$router.push({ path: self.$route.query.source_url }).catch(()=>{});
                    }
                }
            }else{
                self.$router.push({ path: '/index' }).catch(()=>{});
            }
        },
        // end 

        check_dd_oauth2(code, state, site){
            // 登录
            let self = this;
            let params = {
                code, 
                state,
                site,   // 来源网站
            }
            ddCallback(params).then(response => {
                if (response.data.code === 0) {
                    // console.log("登录成功")
                    // console.log(response.data.data);
                    // self.$Message.success(response.data.msg);
                    // 记录token
                    let user = response.data.data;
                    self.do_store_user(user);
                    
                } else {
                    // self.$Message.error(response.data.msg);
                    self.msg = response.data.msg ;
                }
            })
        },
        // end
        check_cas_oauth2(code, state){
            let self = this;
            let params = {
                code, 
                state,
            }
            casCallback(params).then(response => {
                if (response.data.code === 0) {
                    // 记录token
                    let user = response.data.data;
                    self.do_store_user(user);
                } else {
                    // self.$Message.error(response.data.msg);
                    self.msg = response.data.msg ;
                }
            })
        },
        // end 
    },
    mounted () {
        let self = this;
        // 获取传参
        // console.log(self.$route)
        self.msg = '认证中...'
        let code = self.$route.query.code;
        let state= self.$route.query.state;
        console.log(code, state);
        if (code && state){
            self.check_cas_oauth2(code, state);
        }else{
            self.msg = '404 Not Found';
        }
    },
    watch: {
    },
    computed: {}
}
</script>